<template>
  <article class="page-contents-wrap">
    <sub-title :text="'벤더사별 게임수수료'">
      <div id="uiWrap">
        <!--
        <input type="number" class="mr-5"/>
        <button type="button" class="btn-layout btn-grey mr-5" title="새로고침" @click="refreshData">
          <i class="fas fa-redo"></i>
        </button>
        <button type="button" class="btn-layout btn-blue mr-5" >일괄적용</button>
        <button type="button" class="btn-layout btn-blue">{{ $t('button.save') }}</button>-->
        <button type="button" class="btn-layout btn-blue rightbtn" @click="afterSaveAndGetData">설정된 요율데이터 가져와서 저장하기</button>
      </div>
    </sub-title>
    <div class="page-content wrapper-contents itemz">
      <ul id="tabList">
        <li @click="currentTab = 'casino'" :class="{'on': currentTab === 'casino'}" data-tab="tab-1">카지노</li>
        <li @click="currentTab = 'slot'" :class="{'on': currentTab === 'slot'}" data-tab="tab-2">슬롯</li>
      </ul>
      <div class="main-contents-wrapper">
        <div class="table-wrapper">
          <table class="popupTable">
            <colgroup>
              <col width="19%">
              <col width="19%">
              <col width="19%">
              <col width="19%">
<!--              <col width="19%">-->
<!--              <col width="5%">-->
            </colgroup>
            <thead>
              <tr>
                <th>게임사</th>
                <th>벤더사 선택</th>
                <th>나의 현재 요율 ( {{siteData.siteId}} )</th>
                <th>( {{ targetSiteId }} ) 의 현재 요율</th>
<!--                <th>( {{ targetSiteId }} ) 의 수정할 요율</th>-->
<!--                <th>{{ $t('button.save') }}</th>-->
              </tr>
            </thead>
            <tbody class="tablebodyWrap">
              <template v-for="item in getTargetList" :key="item.productCode">
                <tr v-for="(detail, index) in item.children" :key="detail.vendor">
                  <template v-if="index === 0">
                    <td :rowspan="item.children.length">{{item.productName}}</td>
                  </template>
                  <td>{{detail.vendorName}} ({{detail.vendor}})</td>
                  <td :class="{'select' : detail.topUseYn === 'Y'}">{{detail.topGameRate}}%</td>
                  <td :class="{'select' : detail.botUseYn === 'Y'}">{{detail.botGameRate}}%</td>
<!--                  <td>{{detail.botGameRate}}%</td>-->
<!--                  <template v-if="index === 0">-->
<!--                    <td :rowspan="item.children.length"><button class="btn-layout btn-blue">{{ $t('button.save') }}</button></td>-->
<!--                  </template>-->
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </article>
</template>
<script>

import subTitle from '@/components/main/PageSubTitle'
import { getVendorRating, setVendorRating } from '@/api/company'
import { mapState } from 'vuex'
export default {
  name: 'venderRateSetting',
  components: {
    subTitle
  },
  props: [
    'id'
  ],
  data: function () {
    return {
      memberDetail: {},
      rateList: [],
      currentTab: 'casino', // casino, slot
      targetSiteId: this.$route.params.id,
      list: {}
    }
  },
  computed: {
    ...mapState([
      'siteData'
    ]),
    getTargetList () {
      return this.list[this.currentTab]
    }
  },
  methods: {
    async afterSaveAndGetData () {
      const confirmMessage = '상위 벤더 회사(트리플A)에서 요율을 가져와서 저장합니다. 기존에 저장되어있는 데이더를 덮어쓰게 됩니다. 진행하시겠습니까?'
      if (confirm(confirmMessage)) {
        this.emitter.emit('Loading', true)
        await setVendorRating({ siteId: this.targetSiteId }).then(async res => {
          if (res.resultCode === '0') {
            const result = res.data.list
            await this.dataProcessing(result)
            this.emitter.emit('Loading', false)
            alert('저장이 완료되었습니다.')
          }
        })
      } else {
        return false
      }
    },
    async loadData () {
      this.emitter.emit('Loading', true)
      await getVendorRating({ siteId: this.targetSiteId }).then(async res => {
        if (res.resultCode === '0') {
          const result = res.data.list
          console.log(result)
          await this.dataProcessing(result)
          this.emitter.emit('Loading', false)
        }
      })
    },
    async dataProcessing (data) {
      const casinoList = data.filter(item => item.category === 'casino')
      const slotList = data.filter(item => item.category === 'slot')

      const casino = this.productGrouping(casinoList)
      const slot = this.productGrouping(slotList)

      this.list = {
        casino,
        slot
      }
    },
    productGrouping (list) {
      const grouping = {}
      list.forEach(item => {
        const productCode = item.productCode

        if (!grouping[productCode]) {
          grouping[productCode] = {
            productCode,
            productName: item.productName,
            children: []
          }
        }
        grouping[productCode].children.push(item)
      })
      return grouping
    }
  },
  async created () {
    await this.loadData()
  }
}
</script>
<style scoped>
body{
  height:100vh;
}
#uiWrap{
  width: 100%;
  position: relative;
}
.rightbtn{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.main-contents-wrapper{
  width: 100%;
}
.page-contents-wrap{
  margin-bottom:0;
  height:100%;
  max-width: 1200px;
  overflow: hidden;
}
.sub-title {
  padding: 10px 15px;
}
.sub-title >>> h3 {width: 100%;}
.sub-title input[type=number]{
  width:80px;
}
</style>
